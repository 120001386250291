@import '../styles/abstracts/variables.module';

.pieChartThermometer {
  width: 180px;
  display: inline-block;
  padding-top: 90px;
}

.chartThermometerRoot {
  position: relative;
  z-index: 0;
}

.cLineChart {
  /* align-self: stretch; */
}

.rondEindex {
  position: absolute;
  top: 154px;
  border: 4px solid #edf0f7;
}

.thermometerRoot {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 1;
}

.thermometer {
  position: relative;
  width: 70px;
  text-align: center;
}

.thermometerBody {
  height: 160px;
  width: 30px;
  background-color: #edf0f7;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  position: absolute;
  left: 20px;
  top: 0;
}

.thermometerInner {
  z-index: 5;
  width: 22px;
  position: absolute;
  left: 4px;
  height: 156px;
  bottom: 0;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.thermometerEindex {
  z-index: 6;
  width: 30px;
  background-color: #edf0f7;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  position: absolute;
  top: 0;
}

.containVerbatimbubble {
  max-height: 50vh !important;
  overflow-y: auto;
  background-color: $gray-200;
  border-top: 1px solid #ccc;
}
