@charset "UTF-8";

@import '~bootstrap/scss/functions';
@import '~video-react/styles/scss/video-react';
// 1. Configuration and helpers
@import 'abstracts/variables.module';
@import '~bootstrap/scss/bootstrap';

$fontSizes: (
  'h1': 2.25,
  'h2': 2,
  'h3': 1.75,
  'h4': 1.5,
  'h5': 1.25,
  'h6': 1.125,
  'body': 1,
  'body-small': 0.875,
  'caption': 0.75,
  'legal': 0.625,
);

@each $current-name, $current-size in $fontSizes {
  .size-font-#{$current-name} {
    font-size: #{$current-size}rem;
  }
}

//
//.btn-round{
//  &.btn-sm {
//    border-radius: 15px;
//  }
//}

.btn {
  @include button-size(
    $btn-padding-y,
    $btn-padding-x,
    $font-size-sm,
    $btn-line-height,
    $btn-border-radius
  );
}

.btn-lg {
  @include button-size(
    $btn-padding-y-lg,
    $btn-padding-x-lg,
    $font-size-lg,
    $btn-line-height-lg,
    $btn-border-radius-lg
  );
}

.btn-sm {
  @include button-size(
    $btn-padding-y-sm,
    $btn-padding-x-sm,
    $font-size-caption,
    $btn-line-height-sm,
    $btn-border-radius-sm
  );
}

label {
  font-weight: bold;

  @include fontSize(14px);
}

.text-underline {
  text-decoration: underline !important;
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}

.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}

.react-grid-item.cssTransforms {
  transition-property: transform;
}

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  background: $secondary;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  border-radius: 0.5rem;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-grid-item > .react-resizable-handle::after {
  content: '';
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-resizable {
  position: relative;
}

.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}

//'abstracts/functions',
//'abstracts/mixins';
//
//// 2. Vendors
//@import
//'vendor/normalize';
//
//// 3. Base stuff
//@import
//'base/base',
//'base/fonts',
//'base/typography',
//'base/helpers';
//
//// 4. Layout-related sections
//@import
//'layout/header',
//'layout/footer';
//
//// 5. Components
//@import
//'components/button';
//
//// 6. Page-specific styles
//@import
//'pages/home';
//
//// 7. Themes
//@import
//'themes/default';

.containContent {
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.selectInput div,
.selectInput div:active {
  border-radius: 20px !important;
  background: $gray-300;
  border: none;
  color: $primary;

  @include fontSize(14px);
}

.selectInput {
  color: $black !important;
}

.selectInput.is-disabled > div {
  opacity: 0.5;
}
