.displayHorizontal {
  display: flex;
  border: none;
}

.buttonSelectionFilter {
  width: 100%;
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
  white-space: nowrap;
}

.buttonSelectionFilterVertical {
  display: flex;
}

.buttonSelectionFilterHorizontal {
  text-align: center;
  border-right: 1px solid #e3e5e8;
}

.buttonSelectionFilter:hover {
  background-color: #f7f9ff;
}

.iconCenter {
  margin: auto;
}

.buttonSelectionText {
  font-size: 16px;
  text-align: left;
  line-height: 24px;
  font-weight: bold;
  margin-left: 1rem;
}

.buttonSelectionTextHorizontal {
  margin: 0 !important;
}

.containSelectionFilter {
  width: 192px !important;
}

.containverbatimBubbles {
  padding-top: 1rem;
  max-height: 65vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.selectInput div,
.selectInput div:active {
  border-radius: 20px !important;
  background: #edf0f7;
  border: none;
  font-size: 13px;
  color: #592ea0;
}

.selectInput {
  color: #2e096b !important;
}

.selectInputMinimal div,
.selectInputMinimal div:active,
.selectInputMinimal div:hover {
  font-size: 13px;
  color: #592ea0;
  border: none;
  text-align: right;
  margin-right: 1.5rem;
}
