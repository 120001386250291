@import '../../../styles/abstracts/variables.module';

.selectInput div,
.selectInput div:active {
  border-radius: 20px !important;
  background: $gray-300;
  border: none;
  color: $primary;

  @include fontSize(14px);
}

.selectInput {
  color: $black !important;
}

.titleCols {
  text-align: center;
  font-weight: bold;

  @include fontSize(18px);
}

.titleCols.success {
  color: $green;
}

.titleCols.error {
  color: $red;
}

.flexContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
}

.flexItem {
  text-align: center;
  align-self: center;
}

.containVerbatimbubble {
  max-height: 73vh !important;
  overflow-y: auto;
  background-color: $gray-200;
  border-top: 1px solid #ccc;
}
