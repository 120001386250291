@import '../../../../../styles/abstracts/variables.module';

.backgroundCircle {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eIndexBenchmarkBis {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.eIndexBenchmarkBis::before {
  content: '';
  background-color: $gray-400;
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 1.25rem;
}

.rondEindex {
  position: relative;
}

.eIndexBenchmarkItem:not(:last-child) {
  margin-bottom: 2rem;
}

.eIndexBenchmarkItemRow::before {
  content: '';
  background-color: red;
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 2.25rem;
}

.eindexBenchmarkItemContainName {
  padding-left: 0;
  font-weight: bold;

  @include fontSize('12px');

  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}
