@import '../../../styles/abstracts/variables.module';

.buttonEmotionFilter {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
  white-space: nowrap;
}

.buttonEmotionFilter:hover {
  background-color: #f7f9ff;
}

.buttomEmotionText {
  font-size: 16px;
  text-align: left;
  line-height: 24px;
  font-weight: bold;
  margin-left: 1rem;
}

.containEmotionFilter {
  width: 192px !important;
}

.containverbatimBubbles {
  padding-top: 1rem;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.selectMinWidth {
  min-width: 200px;
}

.selectInput div,
.selectInput div:active {
  border-radius: 20px !important;
  background: #edf0f7;
  border: none;
  font-size: 13px;
  color: #592ea0;
}

.selectInput {
  color: #2e096b !important;
  margin-bottom: 0 !important;
}

.selectInputMinimal div,
.selectInputMinimal div:active,
.selectInputMinimal div:hover {
  font-size: 13px;
  color: #592ea0;
  border: none;
  text-align: right;
  //margin-right: 1.5rem;
}

.bodyGrey {
  background-color: $gray-200;
  overflow-y: auto;
  overflow-x: hidden;
  height: inherit;
}

.rotate {
  -webkit-animation: rotation 2s infinite linear;
  margin: auto;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}

.inlineIcon {
  display: inherit;
  margin-right: 1em;
}
