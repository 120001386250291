@import 'styles/abstracts/_colors.scss';
@import 'styles/abstracts/_typography.scss';

.SingleDatePicker {
  width: 100%;
}

.SingleDatePickerInput__withBorder {
  border-radius: 25px;
  border: none;
  width: 100%;
}

.DateInput_input {
  border-radius: 25px;
  background-color: $gray-300;
  color: $black;
  font-weight: bold;
  font-size: $font-size-sm;
  width: 100%;
  border: none;
}

.DateInput {
  //width: 100%;
}

.DateInput_input__focused {
  border: none;
}

.CalendarDay__selected {
  background-color: $purple;
  border: 1px double $purple;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid $purple;
}
