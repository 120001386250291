@import '../../styles/abstracts/variables.module';

.rondEindex > div {
  line-height: 39px;
}

.bubbledate {
  font-size: 11px;
  color: #928fb0 !important;
  text-align: left;
  line-height: 18px;
  margin-bottom: 0;
  font-weight: bold;
}

.bubbleAdditionnalInfos {
  font-size: 14px;
  color: #928fb0 !important;
  text-align: left;
  line-height: 21px;
}

.buttonExpand {
  width: 2rem;
  height: 1rem;
  border-radius: 1rem 1rem 0 0;
  background-color: #edf0f7;
  position: absolute;
  bottom: 0;
  left: calc(50% - 1rem);
  text-align: center;
}

.buttonExpand svg {
  position: relative;
  margin-top: -1.5rem;
  cursor: pointer;
}

.bubbleExpanded {
  height: auto;
  transition: max-height 0.25s ease-in-out;
}

.bubbleCollapsed {
  /* max-height: 65px; */
  height: auto;
  transition: max-height 0.25s ease-in-out;
  overflow: hidden;
  font-size: 16px;
  color: #222c66;
  text-align: left;
  line-height: 32px;
}

.showMore {
  display: block;
}

.buttonHide {
  display: none;
}

.buttonShow {
  display: block;
}

.divMainEmotion {
  font-size: 12px;
  color: #fff;
  text-align: center;
  line-height: 18px;
  height: 24px;
  width: fit-content;
  border-radius: 12px;
  padding: 3px 12px;
  font-weight: bold;
  margin: auto;
  text-transform: capitalize;
}

.scoreLabel {
  font-weight: bold;
  font-size: 11px;
  color: #2e096b;
  text-align: center;
  line-height: 12px;
}

.score {
  font-weight: bold;
  font-size: 14px;
  color: #592ea0;
  text-align: center;
  line-height: 21px;
}

.addInfoValue {
  font-weight: bold;
}

.addInfoType {
  font-size: 16px;
  color: #2e096b;
  text-align: left;
  line-height: 24px;
  font-weight: bold;
}

.rowAdditionalInfo {
  margin-left: -32px !important;
  padding-top: 8px;
  padding-bottom: 22px;
  text-align: left;
}

.iconCSS {
  margin: 8px 0 8px 16px;
}

.tagThematic {
  background-color: $gray-300;
  border-radius: 12px;
  font-size: 12px;
  color: #928fb0;
  text-align: center;
  line-height: 18px;
  font-weight: bold;
  padding: 3px 12px;
  margin-right: 4px;
  margin-top: 8px;
  position: relative;
  cursor: pointer;
}

.tooltipThematic {
  position: absolute;
  margin-top: -48px;
  z-index: 30;
  background-color: #000;
  visibility: hidden;
  text-align: center;
  padding: 2px 4px;
  border-radius: 8px;
  color: #fff;
  width: max-content;
  font-size: 10px;
  left: 50%;
  box-shadow: 2px 2px 10px #000;
}

.tooltipThematic::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 12px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tagThematic:hover > .tooltipThematic {
  visibility: visible;
}
