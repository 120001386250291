@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:200,400,700|Nunito:200,400,700');

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: 'Nunito Sans', sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  'Liberation Mono', 'Courier New', monospace !default;
$font-family-base: $font-family-sans-serif;
$headings-font-family: 'Nunito', sans-serif;
// stylelint-enable value-keyword-case

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: ($font-size-base * 1);
$font-size-sm: ($font-size-base * 0.875);
$font-size-caption: ($font-size-base * 0.75);

$h1-font-size: $font-size-base * 2.25;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base * 1.125;

$headings-line-height: 1.25;

$font-weight-light: 200;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
