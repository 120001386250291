// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 600px,
  md: 900px,
  lg: 1200px,
  xl: 1800px,
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 568px,
  md: 852px,
  lg: 1136px,
  xl: 1920px,
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width: 32px;
