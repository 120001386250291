$purple: #592ea0;
$red: #db2156;
$orange: #ff8104;
$yellow: #f5d800;
$green: #8bdb00;
$light-blue: #0ec3e3;
$blue: #007aff;
$dark-blue: #222c66;

$red-blue-shade-1: $red;
$red-blue-shade-2: #bd1370;
$red-blue-shade-3: #992085;
$red-blue-shade-4: #6e208c;
$red-blue-shade-5: #492796;
$red-blue-shade-6: $dark-blue;

$white: #fff;
$gray-100: #f7f9ff;
$gray-200: #f2f4fa;
$gray-300: #edf0f7;
$gray-400: #e1e4ed;
$gray-500: #c3c8d9;
$gray-600: #a7adc7;
$gray-700: #9d9ebd;
$gray-800: #928fb0;
$gray-900: #9c82c6;
$black: #2e096b;

$primary: $purple;
$secondary: $gray-800;
$success: $green;
$info: $light-blue;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $black;
