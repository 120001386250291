@import '../../../../styles/abstracts/variables.module';
.containCategoryDetails {
  cursor: pointer;

  &:hover {
    text-decoration: underline;

    .categoryName {
      color: $primary;
    }
  }
}

.containCategoryDetails p {
  margin-bottom: 0;
  width: 100%;
}

.categoryHierarchy {
  text-transform: capitalize;
  font-size: 12px;
  color: #928fb0;
  text-align: left;
  line-height: 18px;
}

.categoryName {
  text-transform: capitalize;
  font-size: 14px;
  color: #2e096b;
  text-align: left;
  line-height: 21px;
}

.categoryNumberOfMentions {
  font-size: 12px;
  color: #928fb0;
  text-align: left;
  line-height: 18px;
}

.rondEindex > div {
  line-height: 70px;
}
