//@import '../variables.css';
//@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:200,400,700|Nunito:200,400,700');
@import '../../../styles/abstracts/variables.module';

.root {
  transition: top 0.3s ease-out, bottom 0.3s ease-out;
  background-color: $white;
}

.rootSidebarContain {
  width: 256px;
  background-color: $white;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.sidebarOpen {
  width: calc(100% - 257px);
}

.sidebarClosed {
  width: 100%;
}

.hideHeader {
  display: none !important;
}

.thematic_item {
  color: $black;
  width: 240px;
  //padding: 0 8px;
  background-color: $white;
}

.accordion_title {
  font-size: 16px;
  color: $black;
  line-height: 24px;
  height: 48px;
  padding: 8px 13px;
}

.accordion > div > div > div > div > div div {
  background-color: $gray-100 !important;
}

.accordion_body {
  background-color: $gray-100 !important;
  padding: 0 0.5rem;
}

.sidebarRoot {
  top: 190px !important;
}

.sidebarRootHideAllHeader {
  top: 0 !important;
}

.sidebarRootHideHeaderCenter {
  top: 80px !important;
}

.sidebar {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.sidebarContent {
  background-color: $body-bg;
}

.headerSidebarDiv {
  //height: 64px;
}

.headerSBcontain {
  padding-top: 12px;
}

.headerSBtitle {
  font-size: 12px;
  color: $black;
  text-align: left;
  line-height: 21px;
  font-weight: bold;
}

.headerSBname {
  text-transform: uppercase;
  margin-bottom: 0.5rem;

  @include fontSize(12px);

  color: $secondary;
  font-weight: bold;
}

.tabs {
  margin-top: 1rem;
  z-index: 2;
}

.otherFilterZone {
  margin: 15px;
  border-bottom: 1px solid #edf0f7;
}

.otherFilterName {
  font-size: 18px;
  color: #2e096b;
  line-height: 22.5px;
  font-weight: bold;
  padding-bottom: 16px;
}

.selectInput div,
.selectInput div:active {
  border-radius: 20px !important;
  background: #edf0f7;
  border: none;
  font-size: 13px;
  color: #2e096b;
}

.selectInput {
  margin-bottom: 16px;
}

.Qtabs {
  font-size: 14px;
  text-align: center;
  line-height: 21px;
  padding-top: 0;
  padding-bottom: 6px;
  font-weight: bold;
  color: #928fb0 !important;
  border: 0 0 4px solid transparent !important;
}

.Qtabs:hover {
  border: 0 0 4px 0 solid inherit !important;
}

.tabActive {
  color: $primary !important;
  border-bottom: 4px solid $primary !important;
}

.containNavSideBar {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tabContent {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}
