@import '../../../styles/abstracts/variables.module';

.Qtabs {
  font-size: 14px;
  text-align: center;
  line-height: 21px;
  padding-top: 0;
  padding-bottom: 6px;
  font-weight: bold;
  color: #928fb0 !important;
  border-width: 0 0 4px !important;
  border-color: white !important;
  box-sizing: padding-box !important;
  cursor: pointer !important;
}

.Qtabs:hover {
  border: 0 0 4px 0 solid inherit !important;
}

.tabSuccessActive {
  color: #8bdb00 !important;
  border-bottom: 4px solid #8bdb00 !important;
}

.tabErrorActive {
  color: #db2156 !important;
  border-bottom: 4px solid #db2156 !important;
}

.tabContent {
  /* max-height: 380px !important; */

  /* flex-grow: 1; */

  /* display: flex; */
  background-color: $gray-200;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  padding: 0;
}

.overflowNone {
  overflow-y: auto;
  flex-grow: 1;
  overflow-x: hidden;
  height: 100% !important;
}

.height100 {
  height: calc(100% - 32px) !important;
}

.minHeightTabs {
  height: 32px;
  background-color: white;
}
