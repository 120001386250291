@import '../../../styles/abstracts/variables.module';

.rond_small {
  height: 40px;
  width: 40px;
  border-radius: 40px;
}

.rond_small > div {
  font-size: 16px;
  letter-spacing: 0;
}

.rond_x_small {
  height: 28px;
  width: 28px;
  border-radius: 28px;
}

.rond_x_small > div {
  font-size: 12px;
  letter-spacing: 0;
}

.rond_medium > div {
  font-size: 24px;
}

.rond_medium {
  height: 70px;
  width: 70px;
  border-radius: 70px;
}

.rond_large > div {
  font-size: 48px;
}

.rond_large {
  height: 128px;
  width: 128px;
  border-radius: 128px;
}

.happinessBG {
  background-color: #db2156;
}

.surpriseBG {
  background-color: #ff8104;
}

.calmBG {
  background-color: #f5d800;
}

.fearBG {
  background-color: #8bdb00;
}

.sadnessBG {
  background-color: #0ec3e3;
}

.angerBG {
  background-color: #007aff;
}

.disgustBG {
  background-color: #222c66;
}

.happinessColor {
  color: #db2156;
}

.surpriseColor {
  color: #ff8104;
}

.calmColor {
  color: #f5d800;
}

.fearColor {
  color: #8bdb00;
}

.sadnessColor {
  color: #0ec3e3;
}

.angerColor {
  color: #007aff;
}

.disgustColor {
  color: #222c66;
}

.eindex40Color {
  color: #db2156;
}

.eindex30Color {
  color: #bd1370;
}

.eindex20Color {
  color: #992085;
}

.eindex10Color {
  color: #6e208c;
}

.eindex0Color {
  color: #492796;
}

.eindexminus10Color {
  color: #222c66;
}

.eindex40BG {
  background-color: #db2156;
}

.eindex30BG {
  background-color: #bd1370;
}

.eindex20BG {
  background-color: #992085;
}

.eindex10BG {
  background-color: #6e208c;
}

.eindex0BG {
  background-color: #492796;
}

.eindexminus10BG {
  background-color: #222c66;
}

.noEindexBgColor {
  background-color: $gray-300;
}

.nodata {
  font-size: 38px !important;
  line-height: 38px;
  color: $gray-500 !important;
}

.rond_small .nodata {
  font-size: 0.7rem !important;
  line-height: 0.7rem;
}

.rotate {
  -webkit-animation: rotation 2s infinite linear;
  margin-top: 4px;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}

.mentionCount {
  line-height: 0.4;
}

.mentionCount > div {
  font-size: 2.5rem;
  width: 100%;
}

.black {
  color: #2e096b;
}
