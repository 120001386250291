@import '../../styles/abstracts/variables.module';

$dotUnit: 2rem;
$barHeight: 0.25rem;

.bs_wizard > .bs_wizard_step {
  padding: 0;
  position: relative;
}

.bs_wizard_info {
  color: #999;
  font-size: 14px;
}

.bs_wizard_dot {
  position: absolute;
  width: $dotUnit;
  height: $dotUnit;
  display: block;
  background: $primary;
  top: 0;
  left: 50%;
  margin-left: -$dotUnit/2;
  border-radius: 50%;
}

.bs_wizard_step {
  counter-increment: steps;
}

.bs_wizard > .bs_wizard_step.stepWithNumber > .bs_wizard_dot::after {
  content: counter(steps);
  width: 100%;
  height: 100%;
  border-radius: $dotUnit/2;
  position: absolute;
  line-height: $dotUnit;
  text-align: center;
  color: $white;
  font-weight: bold;
}

//.bs_wizard > .bs_wizard_step > .bs_wizard_dot:after {
//  content: 'A';
//  width: 14px;
//  height: 14px;
//  background: #fbbd19;
//  border-radius: 50px;
//  position: absolute;
//  top: 8px;
//  left: 8px;
//}

.bs_wizard > .bs_wizard_step > .progressbar {
  position: relative;
  border-radius: 0;
  height: $barHeight;
  box-shadow: none;
  margin: (($dotUnit - $barHeight) / 2) 0;
}

.bs_wizard > .bs_wizard_step.disabled > .bs_wizard_dot {
  background-color: $gray-300;
}

.bs_wizard > .bs_wizard_step.disabled > .bs_wizard_dot::after {
  color: $secondary;
}

.bs_wizard > .bs_wizard_step:first-child > .progressbar {
  left: 50%;
  width: 50%;
}

.bs_wizard > .bs_wizard_step:last-child > .progressbar {
  width: 50%;
}

.bs_wizard > .bs_wizard_step.disabled a.bs_wizard_dot,
.bs_wizard > .bs_wizard_step.active a.bs_wizard_dot {
  pointer-events: none;
}

.active .bs_wizard_info {
  color: $primary;
  font-weight: bold;
}

.complete {
  width: inherit;
}
