@import '../../../styles/abstracts/variables.module';

.Qtabs {
  font-size: 14px;
  text-align: center;
  line-height: 21px;
  padding-top: 0;
  padding-bottom: 6px;
  font-weight: bold;
  color: #928fb0 !important;
  border: 0 0 4px solid transparent !important;
}

.Qtabs:hover {
  border: 0 0 4px 0 solid inherit !important;
}

.tabActive {
  color: $primary !important;
  border-bottom: 4px solid $primary !important;
}

.tabSuccessActive {
  color: #8bdb00 !important;
  border-bottom: 4px solid #8bdb00 !important;
}

.tabErrorActive {
  color: #db2156 !important;
  border-bottom: 4px solid #db2156 !important;
}

.tabContent {
  /* max-height: 380px !important; */

  /* flex-grow: 1; */

  /* display: flex; */
}

.overflowNone {
  overflow-y: hidden;
  overflow-x: hidden;
  flex-grow: 1;
  height: 100%;
}
