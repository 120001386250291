@import '../../../../../styles/abstracts/variables.module';

.root {
  margin: 28px 32px;
  box-shadow: 0 0 15px 0 rgba(80, 71, 71, 0.35);
  height: 156px;
  border-radius: 7px;
}

.contain {
  display: flex;
  width: 100%;
  overflow-x: auto;
  min-width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.eindex_rond {
  border: 3px solid #fff;
  position: absolute;
  bottom: 18px;
  left: calc(50% + 15px);
}

.item {
  text-align: center;
  height: 156px;
  position: relative;
  // cursor: pointer;
  min-width: 200px;
  flex-grow: 1;

  /* width: calc(25% - 30px); */
}

.noIndexColor {
  color: $secondary !important;
}

.title {
  font-size: 17px;
  letter-spacing: 0;
  font-weight: bold;
  padding-top: 17px;
  padding-bottom: 6px;
  //color: #928fb0;
}

.border_bottom {
  height: 4px;
  width: 100%;
  position: absolute;
  bottom: 0;
  border-radius: 3px;
}

.arrow {
  width: 40px;
  height: 156px;
}

.arrow_icon {
  height: 40px;
  width: 40px;
}

.inner_center {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
